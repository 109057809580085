.InformativeBannerWithLinks {
    margin-bottom: 70px;
    @media (min-width: 992px) {
        margin-bottom: 143px;
    }
    .infographicBannerContainer {
        display: flex;
        flex-direction: column;

        @media (min-width: 992px) {
            flex-direction: row;
        }

        .textSection {
            display: flex;
            flex-direction: column;
            padding-right: 0;

            @media (min-width: 992px) {
                padding-right: 85px;
            }

            .contentSection {
                .textSectionTitle {
                    font-style: normal;
                    color: #ffffff;
                    font-weight: 400;
                    font-size: 33px;
                    line-height: 107.19%;
                    margin-bottom: 19px;

                    @media (min-width: 768px) {
                        font-weight: 400;
                        font-size: 66px;
                        line-height: 107.19%;
                        margin-bottom: 47px;
                    }
                }
                .textSectionDescription {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 124.69%;
                    color: #ffffff;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    margin-bottom: 38px;

                    @media (min-width: 768px) {
                        font-size: 20px;
                        line-height: 124.69%;
                        margin-bottom: 31px;
                    }
                }
            }
            .buttonSection {
                .featuresButton {
                    margin-bottom: 26px;
                    @media (min-width: 768px) {
                        margin-bottom: 49px;
                    }
                }
                .appsLinksContainer {
                    @media (max-width: 576px) {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .socialIconsBottomLinks {
                        img {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
        .imageSection {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            @media (min-width: 992px) {
                margin-top: 0;
                justify-content: flex-start;
            }
            @media (min-width: 1450px) {
                justify-content: center;
            }
            .infographicImage {
                width: 95%;

                @media (min-width: 576px) {
                    width: 65%;
                }
                @media (min-width: 768px) {
                    width: 85%;
                }
                @media (min-width: 992px) {
                    width: 100%;
                }
                @media (min-width: 1450px) {
                    width: 85%;
                }
            }
        }
    }
}
