.TestimonialsSlider {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        margin-top: 66px;
    }

    .testimonialsTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 23px;

        @media (min-width: 768px) {
            font-weight: 500;
            font-size: 55px;
            line-height: 113.19%;
            margin-bottom: 103px;
        }
    }
    .slick-prev,
    .slick-next {
        width: 25px;
        height: 14px;
        @media (min-width: 576px) {
            width: 77px;
            height: 42px;
        }
    }

    .slick-next {
        right: 5px;

        @media (min-width: 768px) {
            right: 70px;
        }

        @media (min-width: 576px) {
            right: -35px;
        }
    }
    .slick-prev {
        left: 5px;

        @media (min-width: 768px) {
            left: 70px;
        }

        @media (min-width: 576px) {
            left: -35px;
        }
    }

    .removePseudo {
        &:after {
            content: none;
        }
    }
    .reviewContainer {
        cursor: pointer;
        position: relative;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 125px;
        padding: 0 10px;

        &.addBorder {
            &:after {
                content: "";
                width: 100%;
                height: 235px;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                border-right: 1px solid #00ffe7;
            }
        }

        @media (min-width: 992px) {
            padding: 0 54px;
        }

        .reviewText {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 16px;
            padding: 0 20px;

            @media (min-width: 768px) {
                font-size: 17px;
                line-height: 20px;
                margin-bottom: 25px;
            }
        }

        .reviewer {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 8px;

            @media (min-width: 768px) {
                font-weight: 700;
                font-size: 17px;
                line-height: 20px;
                margin-bottom: 33px;
            }
        }
        .starContainer {
            display: flex;
            flex-direction: row;
            .starIcon {
                width: 12.1px;
                height: 12.1px;
                &:not(:last-of-type) {
                    margin-right: 6.91px;
                }

                @media (min-width: 768px) {
                    width: 31px;
                    height: 31px;
                    &:not(:last-of-type) {
                        margin-right: 17.71px;
                    }
                }
            }
        }
    }
}
