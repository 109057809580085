.Footer {
    margin-top: 14px;
    border-top: none;
    display: flex;
    flex-direction: column;
    @media (min-width: 576px) {
        margin-top: 75px;
        border-top: 0.3px solid rgba(216, 220, 224, 0.3);
    }

    @media (min-width: 576px) {
        margin-top: 137px;
    }

    .topSectionContainer {
        padding-top: 53px;
        .contentColumn {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .singleColumn {
                display: flex;
                flex-direction: column;
                @media (max-width: 992px) {
                    &:nth-last-child(-n + 2) {
                        padding-top: 20px;
                    }
                }
                .columnTitle {
                    margin-top: 0;
                    margin-bottom: 16px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 26px;
                    color: #75cccc;
                }
                .listContent {
                    a,
                    p {
                        margin-top: 0;
                        margin-bottom: 20px;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 32px;
                        color: #ffffff;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .bottomSectionContainer {
        margin-top: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media (min-width: 576px) {
            margin-bottom: 44px;
            padding-top: 60px;
        }

        @media (min-width: 992px) {
            margin-bottom: 98px;
            padding-top: 123px;
        }

        .topRowContainer {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-bottom: 0;

            @media (min-width: 576px) {
                margin-bottom: 20px;
                flex-direction: column-reverse;
            }

            @media (min-width: 992px) {
                flex-direction: row;
                margin-bottom: 41px;
            }

            .exceptionMobileLinkLogo {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .logoSectionContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                @media (min-width: 576px) {
                    justify-content: flex-start;
                }
                .imageContainer {
                    max-width: 100%;
                    padding-bottom: 25px;
                    @media (min-width: 576px) {
                        max-width: unset;
                        padding-bottom: 0;
                        &:not(:last-of-type) {
                            margin-right: 45px;
                        }
                    }
                    img {
                        width: 100%;
                        @media (min-width: 576px) {
                            max-width: 100%;
                        }
                    }
                }
            }
            .socialSectionLinks {
                padding-left: 0;
                padding-top: 20px;
                display: none;
                flex-direction: row;
                align-items: flex-start;

                @media (min-width: 576px) {
                    display: flex;
                    padding-bottom: 20px;
                    padding-top: 0;
                }
                @media (min-width: 992px) {
                    padding-left: 37px;
                    padding-bottom: 0;
                    padding-top: 20px;
                }

                .labelLink {
                    &:not(:last-child) {
                        margin-right: 46px;
                    }
                    span {
                        display: inline-block;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 23px;
                        line-height: 27px;
                        color: #ffffff;
                    }
                }
            }
        }
        .bottomRowContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            &:before {
                content: "";
                border-top: 0.3px solid rgba(216, 220, 224, 0.3);
                width: 100%;
                right: 0;
                top: 0;
                padding-top: 18px;
            }

            @media (min-width: 576px) {
                flex-direction: column-reverse;
                align-items: flex-start;
                &:before {
                    content: unset;
                }
            }

            @media (min-width: 992px) {
                flex-direction: row;
            }
            .rightsSection {
                text-align: center;
                display: flex;
                flex-direction: column;
                margin-bottom: 14px;

                @media (min-width: 576px) {
                    text-align: left;
                    margin-bottom: 0;
                }
                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 8px;
                    line-height: 9px;

                    color: #ffffff;

                    @media (min-width: 576px) {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
            }
            .socialMediaIcons {
                padding-left: 0;
                margin-bottom: 20px;
                @media (min-width: 576px) {
                    margin-bottom: 0;
                    margin-bottom: 15px;
                }
                @media (min-width: 992px) {
                    padding-left: 37px;
                    margin-bottom: 0;
                }
                display: flex;
                flex-direction: row;
                align-items: center;
                align-items: flex-start;

                .socialMediaIcon {
                    &:not(:last-of-type) {
                        margin-right: 15px;
                    }
                    @media (min-width: 576px) {
                        &:not(:last-of-type) {
                            margin-right: 35px;
                        }
                    }
                    img {
                        max-width: 15px;
                        @media (min-width: 576px) {
                            max-width: unset;
                        }
                    }
                }
            }
        }
    }
}
