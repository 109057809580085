.TryNowSection {
    position: relative;
    margin-top: 43.9px;

    @media (min-width: 768px) {
        margin-top: 84px;
    }

    .titleContainer {
        font-style: normal;
        color: #ffffff;
        font-weight: 400;
        font-size: 28px;
        line-height: 104.19%;
        text-align: left;
        width: 100%;

        @media (min-width: 992px) {
            font-weight: 500;
            font-size: 45px;
            line-height: 104.19%;
        }

        @media (min-width: 992px) {
            position: absolute;
            top: 0;
            right: 0;
            max-width: 271px;
            display: flex;
            flex-direction: column;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: 992px) {
                align-items: flex-end;
                text-align: right;
                position: absolute;
                right: 50%;
            }

            .title {
                width: 271px;
                text-align: center;

                @media (min-width: 992px) {
                    width: unset;
                    text-align: right;
                }
            }

            .buttonTryNowSection {
                margin-top: 26px;

                @media (min-width: 992px) {
                    margin-top: 28px;
                }
            }
        }
    }

    .largeOverlayImage {
        width: 100%;
        @media (min-width: 992px) {
            width: 90%;
        }
        @media (min-width: 1200px) {
            width: 100%;
        }

        @media (min-width: 1650px) {
            width: 60%;
        }
    }
}
